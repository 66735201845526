/* .text_input > label {

} */

.passwordContanier {
  position: relative;
  max-height: 3rem;
}

.password_contanier_medium {
  max-height: 4rem;
}

.passwordContanier svg {
  position: absolute;
  top: 20%;
  right: 5%;
}

.password_contanier_medium svg {
  top: 25%;
}

.outline_input_no_border {
  border: none;
}
