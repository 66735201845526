.dropdownInput {
  cursor: pointer;
  padding: 20px;
  border: 1px dashed #c5b7b7;
  border-radius: 2px;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropdownInput:hover {
  border-color: #2196f3;
}
