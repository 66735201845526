.container {
  padding: 4px 12px;
  box-shadow: 0 1px 6px var(--brand-gray-secondary);
  border: 1px solid;
  border-radius: 6px;
  position: absolute;
  left: 52%;
  z-index: 2000;
  opacity: 0.8;
  max-width: 40vw;
  min-width: 40vw;
  animation-duration: 0.4s;
  animation-name: shadow;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  text-align: center;
}

.top_center_alert {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 10%;
}

.bottom_center_alert {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 10%;
}

.success_alert {
  border: 1px solid var(--brand-success-primary);
  background-color: var(--brand-success-secondary);
}

.success_alert p {
  color: var(--brand-success-primary);
}

.error_alert {
  border: 1px solid var(--brand-error-primary);
  background-color: var(--brand-error-secondary);
}

.error_alert p {
  color: var(--brand-error-primary);
}

.warning_alert {
  border: 1px solid var(--brand-warning-primary);
  background-color: var(--brand-warning-secondary);
}

.warning_alert p {
  color: var(--brand-warning-primary);
}

.info_alert {
  border: 1px solid var(--brand-info-primary);
  background-color: var(--brand-info-secondary);
}

.info_alert p {
  color: var(--brand-info-primary);
}

.primary_alert {
  border: 1px solid var(--brand-primary);
  background-color: var(--brand-secondary);
}

.primary_alert p {
  color: var(--brand-primary);
}

.no_border {
  border: none;
}

@keyframes shadow {
  from {
    box-shadow: 0 0 0 var(--brand-gray-secondary);
  }
  to {
    box-shadow: 0 2px 9px var(--brand-gray-secondary);
  }
}
