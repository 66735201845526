.display64,
.display50,
.display40,
.display36,
.headline,
.title,
.subtitle,
.paragraph16,
.paragraph14,
.caption13,
.caption12,
.caption11,
.caption10 {
  margin: 0;
  color: var(--brand-text-primary);
}

/* Display Style */
.display64,
.display64[class*="weight_medium"] {
  font-weight: var(--font-en-medium-display-64-fontWeight);
  font-size: var(--font-en-medium-display-64-fontSize);
  font-family: var(--font-en-medium-display-64-fontFamily);
  line-height: var(--font-en-medium-display-64-lineHeight);
  font-style: var(--font-en-medium-display-64-fontStyle);
  letter-spacing: var(--font-en-medium-display-64-letterSpacing);
}

.display64[class*="weight_bold"] {
  font-weight: var(--font-en-bold-display-64-fontWeight);
  font-size: var(--font-en-bold-display-64-fontSize);
  font-family: var(--font-en-bold-display-64-fontFamily);
  line-height: var(--font-en-bold-display-64-lineHeight);
  font-style: var(--font-en-bold-display-64-fontStyle);
  letter-spacing: var(--font-en-bold-display-64-letterSpacing);
}

.display64[class*="weight_light"] {
  font-weight: var(--font-en-light-display-64-fontWeight);
  font-size: var(--font-en-light-display-64-fontSize);
  font-family: var(--font-en-light-display-64-fontFamily);
  line-height: var(--font-en-light-display-64-lineHeight);
  font-style: var(--font-en-light-display-64-fontStyle);
  letter-spacing: var(--font-en-light-display-64-letterSpacing);
}

.display50,
.display50[class*="weight_bold"] {
  font-weight: var(--font-en-bold-display-50-fontWeight);
  font-size: var(--font-en-bold-display-50-fontSize);
  font-family: var(--font-en-bold-display-50-fontFamily);
  line-height: var(--font-en-bold-display-50-lineHeight);
  font-style: var(--font-en-bold-display-50-fontStyle);
  letter-spacing: var(--font-en-bold-display-50-letterSpacing);
}

.display50[class*="weight_light"] {
  font-weight: var(--font-en-light-display-50-fontWeight);
  font-size: var(--font-en-light-display-50-fontSize);
  font-family: var(--font-en-light-display-50-fontFamily);
  line-height: var(--font-en-light-display-50-lineHeight);
  font-style: var(--font-en-light-display-50-fontStyle);
  letter-spacing: var(--font-en-light-display-50-letterSpacing);
}

.display50[class*="weight_medium"] {
  font-weight: var(--font-en-medium-display-50-fontWeight);
  font-size: var(--font-en-medium-display-50-fontSize);
  font-family: var(--font-en-medium-display-50-fontFamily);
  line-height: var(--font-en-medium-display-50-lineHeight);
  font-style: var(--font-en-medium-display-50-fontStyle);
  letter-spacing: var(--font-en-medium-display-50-letterSpacing);
}

.display40,
.display40[class*="weight_bold"] {
  font-weight: var(--font-en-bold-display-40-fontWeight);
  font-size: var(--font-en-bold-display-40-fontSize);
  font-family: var(--font-en-bold-display-40-fontFamily);
  line-height: var(--font-en-bold-display-40-lineHeight);
  font-style: var(--font-en-bold-display-40-fontStyle);
  letter-spacing: var(--font-en-bold-display-40-letterSpacing);
}

.display40[class*="weight_light"] {
  font-weight: var(--font-en-light-display-40-fontWeight);
  font-size: var(--font-en-light-display-40-fontSize);
  font-family: var(--font-en-light-display-40-fontFamily);
  line-height: var(--font-en-light-display-40-lineHeight);
  font-style: var(--font-en-light-display-40-fontStyle);
  letter-spacing: var(--font-en-light-display-40-letterSpacing);
}

.display40[class*="weight_medium"] {
  font-weight: var(--font-en-medium-display-40-fontWeight);
  font-size: var(--font-en-medium-display-40-fontSize);
  font-family: var(--font-en-medium-display-40-fontFamily);
  line-height: var(--font-en-medium-display-40-lineHeight);
  font-style: var(--font-en-medium-display-40-fontStyle);
  letter-spacing: var(--font-en-medium-display-40-letterSpacing);
}

.display36,
.display36[class*="weight_bold"] {
  font-weight: var(--font-en-bold-display-36-fontWeight);
  font-size: var(--font-en-bold-display-36-fontSize);
  font-family: var(--font-en-bold-display-36-fontFamily);
  line-height: var(--font-en-bold-display-36-lineHeight);
  font-style: var(--font-en-bold-display-36-fontStyle);
  letter-spacing: var(--font-en-bold-display-36-letterSpacing);
}

/* Titles */
.headline,
.headline[class*="weight_medium"] {
  font-weight: var(--font-en-medium-headline-24-fontWeight);
  font-size: var(--font-en-medium-headline-24-fontSize);
  font-family: var(--font-en-medium-headline-24-fontFamily);
  font-style: var(--font-en-medium-headline-24-fontStyle);
  line-height: var(--font-en-medium-headline-24-lineHeight);
  letter-spacing: var(--font-en-medium-headline-24-letterSpacing);
}

.headline[class*="weight_light"] {
  font-weight: var(--font-en-light-headline-24-fontWeight);
  font-size: var(--font-en-light-headline-24-fontSize);
  font-family: var(--font-en-light-headline-24-fontFamily);
  font-style: var(--font-en-light-headline-24-fontStyle);
  line-height: var(--font-en-light-headline-24-lineHeight);
  letter-spacing: var(--font-en-light-headline-24-letterSpacing);
}

.headline[class*="weight_bold"] {
  font-weight: var(--font-en-bold-headline-24-fontWeight);
  font-size: var(--font-en-bold-headline-24-fontSize);
  font-family: var(--font-en-bold-headline-24-fontFamily);
  font-style: var(--font-en-bold-headline-24-fontStyle);
  line-height: var(--font-en-bold-headline-24-lineHeight);
  letter-spacing: var(--font-en-bold-headline-24-letterSpacing);
}

.title,
.title[class*="weight_medium"] {
  font-weight: var(--font-en-medium-title-20-fontWeight);
  font-size: var(--font-en-medium-title-20-fontSize);
  font-family: var(--font-en-medium-title-20-fontFamily);
  line-height: var(--font-en-medium-title-20-lineHeight);
  font-style: var(--font-en-medium-title-20-fontStyle);
  letter-spacing: var(--font-en-medium-title-20-letterSpacing);
}

.title[class*="weight_light"] {
  font-weight: var(--font-en-light-title-20-fontWeight);
  font-size: var(--font-en-light-title-20-fontSize);
  font-family: var(--font-en-light-title-20-fontFamily);
  line-height: var(--font-en-light-title-20-lineHeight);
  font-style: var(--font-en-light-title-20-fontStyle);
  letter-spacing: var(--font-en-light-title-20-letterSpacing);
}

.title[class*="weight_bold"] {
  font-weight: var(--font-en-bold-title-20-fontWeight);
  font-size: var(--font-en-bold-title-20-fontSize);
  font-family: var(--font-en-bold-title-20-fontFamily);
  line-height: var(--font-en-bold-title-20-lineHeight);
  font-style: var(--font-en-bold-title-20-fontStyle);
  letter-spacing: var(--font-en-bold-title-20-letterSpacing);
}
.subtitle,
.subtitle[class*="weight_medium"] {
  font-weight: var(--font-en-medium-subtitle-18-fontWeight);
  font-size: var(--font-en-medium-subtitle-18-fontSize);
  font-family: var(--font-en-medium-subtitle-18-fontFamily);
  line-height: var(--font-en-medium-subtitle-18-lineHeight);
  font-style: var(--font-en-medium-subtitle-18-fontStyle);
  letter-spacing: var(--font-en-medium-subtitle-18-letterSpacing);
}

.subtitle[class*="weight_light"] {
  font-weight: var(--font-en-light-subtitle-18-fontWeight);
  font-size: var(--font-en-light-subtitle-18-fontSize);
  font-family: var(--font-en-light-subtitle-18-fontFamily);
  line-height: var(--font-en-light-subtitle-18-lineHeight);
  font-style: var(--font-en-light-subtitle-18-fontStyle);
  letter-spacing: var(--font-en-light-subtitle-18-letterSpacing);
}

.subtitle[class*="weight_bold"] {
  font-weight: var(--font-en-bold-subtitle-18-fontWeight);
  font-size: var(--font-en-bold-subtitle-18-fontSize);
  font-family: var(--font-en-bold-subtitle-18-fontFamily);
  line-height: var(--font-en-bold-subtitle-18-lineHeight);
  font-style: var(--font-en-bold-subtitle-18-fontStyle);
  letter-spacing: var(--font-en-bold-subtitle-18-letterSpacing);
}

/* Paragraphs  */
.paragraph16,
.paragraph16[class*="weight_medium"] {
  font-weight: var(--font-en-medium-paragraph-16-fontWeight);
  font-size: var(--font-en-medium-paragraph-16-fontSize);
  font-family: var(--font-en-medium-paragraph-16-fontFamily);
  font-style: var(--font-en-medium-paragraph-16-fontStyle);
  line-height: var(--font-en-medium-paragraph-16-lineHeight);
  letter-spacing: var(--font-en-medium-paragraph-16-letterSpacing);
}

.paragraph16[class*="weight_bold"] {
  font-weight: var(--font-en-bold-paragraph-16-fontWeight);
  font-size: var(--font-en-bold-paragraph-16-fontSize);
  font-family: var(--font-en-bold-paragraph-16-fontFamily);
  font-style: var(--font-en-bold-paragraph-16-fontStyle);
  line-height: var(--font-en-bold-paragraph-16-lineHeight);
  letter-spacing: var(--font-en-bold-paragraph-16-letterSpacing);
}

.paragraph16[class*="weight_light"] {
  font-weight: var(--font-en-light-paragraph-16-fontWeight);
  font-size: var(--font-en-light-paragraph-16-fontSize);
  font-family: var(--font-en-light-paragraph-16-fontFamily);
  font-style: var(--font-en-light-paragraph-16-fontStyle);
  line-height: var(--font-en-light-paragraph-16-lineHeight);
  letter-spacing: var(--font-en-light-paragraph-16-letterSpacing);
}

.paragraph14,
.paragraph14[class*="weight_medium"] {
  font-weight: var(--font-en-medium-paragraph-14-fontWeight);
  font-size: var(--font-en-medium-paragraph-14-fontSize);
  font-family: var(--font-en-medium-paragraph-14-fontFamily);
  font-style: var(--font-en-medium-paragraph-14-fontStyle);
  line-height: var(--font-en-medium-paragraph-14-lineHeight);
  letter-spacing: var(--font-en-medium-paragraph-14-letterSpacing);
}

.paragraph14[class*="weight_bold"] {
  font-weight: var(--font-en-bold-paragraph-14-fontWeight);
  font-size: var(--font-en-bold-paragraph-14-fontSize);
  font-family: var(--font-en-bold-paragraph-14-fontFamily);
  font-style: var(--font-en-bold-paragraph-14-fontStyle);
  line-height: var(--font-en-bold-paragraph-14-lineHeight);
  letter-spacing: var(--font-en-bold-paragraph-14-letterSpacing);
}

.paragraph14[class*="weight_light"] {
  font-weight: var(--font-en-light-paragraph-14-fontWeight);
  font-size: var(--font-en-light-paragraph-14-fontSize);
  font-family: var(--font-en-light-paragraph-14-fontFamily);
  font-style: var(--font-en-light-paragraph-14-fontStyle);
  line-height: var(--font-en-light-paragraph-14-lineHeight);
  letter-spacing: var(--font-en-light-paragraph-14-letterSpacing);
}

/* captions */
.caption13 {
  font-weight: var(--font-en-bold-caption-13-fontWeight);
  font-size: var(--font-en-bold-caption-13-fontSize);
  font-family: var(--font-en-bold-caption-13-fontFamily);
  font-style: var(--font-en-bold-caption-13-fontStyle);
  line-height: var(--font-en-bold-caption-13-lineHeight);
  letter-spacing: var(--font-en-bold-caption-13-letterSpacing);
}

.caption13,
.caption13[class*="weight_medium"] {
  font-weight: var(--font-en-medium-caption-13-fontWeight);
  font-size: var(--font-en-medium-caption-13-fontSize);
  font-family: var(--font-en-medium-caption-13-fontFamily);
  font-style: var(--font-en-medium-caption-13-fontStyle);
  line-height: var(--font-en-medium-caption-13-lineHeight);
  letter-spacing: var(--font-en-medium-caption-13-letterSpacing);
}

.caption13[class*="weight_bold"] {
  font-weight: var(--font-en-bold-caption-13-fontWeight);
  font-size: var(--font-en-bold-caption-13-fontSize);
  font-family: var(--font-en-bold-caption-13-fontFamily);
  font-style: var(--font-en-bold-caption-13-fontStyle);
  line-height: var(--font-en-bold-caption-13-lineHeight);
  letter-spacing: var(--font-en-bold-caption-13-letterSpacing);
}

.caption13[class*="weight_light"] {
  font-weight: var(--font-en-light-caption-13-fontWeight);
  font-size: var(--font-en-light-caption-13-fontSize);
  font-family: var(--font-en-light-caption-13-fontFamily);
  font-style: var(--font-en-light-caption-13-fontStyle);
  line-height: var(--font-en-light-caption-13-lineHeight);
  letter-spacing: var(--font-en-light-caption-13-letterSpacing);
}

.caption12 {
  font-weight: var(--font-en-bold-caption-12-fontWeight);
  font-size: var(--font-en-bold-caption-12-fontSize);
  font-family: var(--font-en-bold-caption-12-fontFamily);
  font-style: var(--font-en-bold-caption-12-fontStyle);
  line-height: var(--font-en-bold-caption-12-lineHeight);
  letter-spacing: var(--font-en-bold-caption-12-letterSpacing);
}

.caption12,
.caption12[class*="weight_medium"] {
  font-weight: var(--font-en-medium-caption-12-fontWeight);
  font-size: var(--font-en-medium-caption-12-fontSize);
  font-family: var(--font-en-medium-caption-12-fontFamily);
  font-style: var(--font-en-medium-caption-12-fontStyle);
  line-height: var(--font-en-medium-caption-12-lineHeight);
  letter-spacing: var(--font-en-medium-caption-12-letterSpacing);
}

.caption12[class*="weight_bold"] {
  font-weight: var(--font-en-bold-caption-12-fontWeight);
  font-size: var(--font-en-bold-caption-12-fontSize);
  font-family: var(--font-en-bold-caption-12-fontFamily);
  font-style: var(--font-en-bold-caption-12-fontStyle);
  line-height: var(--font-en-bold-caption-12-lineHeight);
  letter-spacing: var(--font-en-bold-caption-12-letterSpacing);
}

.caption12[class*="weight_light"] {
  font-weight: var(--font-en-light-caption-12-fontWeight);
  font-size: var(--font-en-light-caption-12-fontSize);
  font-family: var(--font-en-light-caption-12-fontFamily);
  font-style: var(--font-en-light-caption-12-fontStyle);
  line-height: var(--font-en-light-caption-12-lineHeight);
  letter-spacing: var(--font-en-light-caption-12-letterSpacing);
}

.caption11,
.caption11[class*="weight_medium"] {
  font-weight: var(--font-en-medium-caption-11-fontWeight);
  font-size: var(--font-en-medium-caption-11-fontSize);
  font-family: var(--font-en-medium-caption-11-fontFamily);
  font-style: var(--font-en-medium-caption-11-fontStyle);
  line-height: var(--font-en-medium-caption-11-lineHeight);
  letter-spacing: var(--font-en-medium-caption-11-letterSpacing);
}

.caption11[class*="weight_bold"] {
  font-weight: var(--font-en-bold-caption-11-fontWeight);
  font-size: var(--font-en-bold-caption-11-fontSize);
  font-family: var(--font-en-bold-caption-11-fontFamily);
  font-style: var(--font-en-bold-caption-11-fontStyle);
  line-height: var(--font-en-bold-caption-11-lineHeight);
  letter-spacing: var(--font-en-bold-caption-11-letterSpacing);
}

.caption11[class*="weight_light"] {
  font-weight: var(--font-en-light-caption-11-fontWeight);
  font-size: var(--font-en-light-caption-11-fontSize);
  font-family: var(--font-en-light-caption-11-fontFamily);
  font-style: var(--font-en-light-caption-11-fontStyle);
  line-height: var(--font-en-light-caption-11-lineHeight);
  letter-spacing: var(--font-en-light-caption-11-letterSpacing);
}

.caption10,
.caption10[class*="weight_medium"] {
  font-weight: var(--font-en-medium-caption-10-fontWeight);
  font-size: var(--font-en-medium-caption-10-fontSize);
  font-family: var(--font-en-medium-caption-10-fontFamily);
  font-style: var(--font-en-medium-caption-10-fontStyle);
  line-height: var(--font-en-medium-caption-10-lineHeight);
  letter-spacing: var(--font-en-medium-caption-10-letterSpacing);
}

.caption10[class*="weight_bold"] {
  font-weight: var(--font-en-bold-caption-10-fontWeight);
  font-size: var(--font-en-bold-caption-10-fontSize);
  font-family: var(--font-en-bold-caption-10-fontFamily);
  font-style: var(--font-en-bold-caption-10-fontStyle);
  line-height: var(--font-en-bold-caption-10-lineHeight);
  letter-spacing: var(--font-en-bold-caption-10-letterSpacing);
}

.caption10[class*="weight_light"] {
  font-weight: var(--font-en-light-caption-10-fontWeight);
  font-size: var(--font-en-light-caption-10-fontSize);
  font-family: var(--font-en-light-caption-10-fontFamily);
  font-style: var(--font-en-light-caption-10-fontStyle);
  line-height: var(--font-en-light-caption-10-lineHeight);
  letter-spacing: var(--font-en-light-caption-10-letterSpacing);
}

[dir="rtl"] .display64,
[dir="rtl"] .display50,
[dir="rtl"] .display40,
[dir="rtl"] .display36,
[dir="rtl"] .headline,
[dir="rtl"] .title,
[dir="rtl"] .subtitle,
[dir="rtl"] .paragraph16,
[dir="rtl"] .paragraph14,
[dir="rtl"] .caption13,
[dir="rtl"] .caption12,
[dir="rtl"] .caption11,
[dir="rtl"] .caption10 {
  font-family: Bahij TheSansArabic !important;
}
