.root {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 42px;
  padding: 8px;
  cursor: pointer;
}

.track {
  background-color: var(--brand-secondary);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
}

.thumb {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--brand-primary);
  width: 36px;
  height: 30px;
  border-radius: 8px;
  top: 3px;
  left: 4px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.thumb p {
  color: var(--brand-text-white);
}

.thumb_1 {
  transform: translateX(12px);
}

.thumb_2 {
  transform: translateX(0);
}
