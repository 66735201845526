/**
 * Do not edit directly
 * Generated on sunday, 23 Jan 2022 10:00:09 GMT
 */

:root {
  /* :::::::::::::::::::::::::::::::::::: Colors :::::::::::::::::::::::::::::::::::: */
  --brand-primary: #521e87;
  --brand-secondary: rgb(227, 209, 245);
  --brand-teritary: #cdcada;
  --brand-background-avatar: rgb(186, 158, 211);
  --brand-background-modal: rgb(247, 244, 250);
  --brand-background: rgb(245, 239, 251);

  --brand-nav-primary: #323232;
  --brand-nav-secondary: #ada5cb;
  --brand-nav-background: rgb(57, 38, 76);
  --brand-nav-hover: rgb(70, 44, 93);

  --brand-success-primary: #148156ff;
  --brand-success-secondary: #c6eadcff;

  --brand-warning-primary: #bf780bff;
  --brand-warning-secondary: #ffe7c3ff;

  --brand-error-primary: rgb(200, 37, 15);
  --brand-error-secondary: #ffd0caff;

  --brand-info-primary: #0d98b3ff;
  --brand-info-secondary: #c4f2fbff;

  --brand-text-primary: rgb(49, 14, 85);
  --brand-text-secondary: rgb(88, 43, 134);
  --brand-text-lighter: rgb(181, 119, 242);
  --brand-text-white: rgb(235, 226, 244);

  --brand-text-link: rgb(6, 69, 173);

  --brand-border-primary: rgb(108, 76, 143);
  --brand-border-secondary: rgb(193, 167, 220);
  --brand-border-lighter: rgb(232, 218, 246);
  --brand-border-input: #9c27b0;
  --brand-border-input-error: var(--brand-error-primary);

  --brand-gray-primary: #323232;
  --brand-gray-secondary: #bdbcbf;
  --brand-gray-standard: rgba(0, 0, 0, 0.42);
  --brand-gray-stroke: #5f5d5d;

  --brand-disabled: rgb(238, 238, 238);

  /* :::::::::::::::::::::::::::::::::::: Fonts ::::::::::::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::: Font Size */
  /* ::::::::::::::::::: Font Family */
  /* ::::::::::::::::::: Font weight */
  /* ::::::::::::::::::: Font Style */
  /* ::::::::::::::::::: Font Letter Spacing */
  /* ::::::::::::::::::: Font line Height */

  /* bold-display-64 */
  --font-en-bold-display-64-fontSize: 64px;
  --font-en-bold-display-64-fontFamily: Nunito;
  --font-en-bold-display-64-fontWeight: 700;
  --font-en-bold-display-64-fontStyle: normal;
  --font-en-bold-display-64-letterSpacing: normal;
  --font-en-bold-display-64-lineHeight: 96px;

  /* bold-display-50 */
  --font-en-bold-display-50-fontSize: 50px;
  --font-en-bold-display-50-fontFamily: Nunito;
  --font-en-bold-display-50-fontWeight: 700;
  --font-en-bold-display-50-fontStyle: normal;
  --font-en-bold-display-50-fontStretch: normal;
  --font-en-bold-display-50-fontStyleOld: Bold;
  --font-en-bold-display-50-letterSpacing: normal;
  --font-en-bold-display-50-lineHeight: 72px;

  /* bold-display-40 */
  --font-en-bold-display-40-fontSize: 40px;
  --font-en-bold-display-40-fontFamily: Nunito;
  --font-en-bold-display-40-fontWeight: 700;
  --font-en-bold-display-40-fontStyle: normal;
  --font-en-bold-display-40-fontStretch: normal;
  --font-en-bold-display-40-fontStyleOld: Bold;
  --font-en-bold-display-40-letterSpacing: normal;
  --font-en-bold-display-40-lineHeight: 54px;

  /* bold-display-36 */
  --font-en-bold-display-36-fontSize: 36px;
  --font-en-bold-display-36-fontFamily: Nunito;
  --font-en-bold-display-36-fontWeight: 700;
  --font-en-bold-display-36-fontStyle: normal;
  --font-en-bold-display-36-fontStretch: normal;
  --font-en-bold-display-36-fontStyleOld: Bold;
  --font-en-bold-display-36-letterSpacing: normal;
  --font-en-bold-display-36-lineHeight: 48px;

  /* bold-headline-24 */
  --font-en-bold-headline-24-fontSize: 24px;
  --font-en-bold-headline-24-fontFamily: Nunito;
  --font-en-bold-headline-24-fontWeight: 700;
  --font-en-bold-headline-24-fontStyle: normal;
  --font-en-bold-headline-24-fontStretch: normal;
  --font-en-bold-headline-24-fontStyleOld: Bold;
  --font-en-bold-headline-24-letterSpacing: normal;
  --font-en-bold-headline-24-lineHeight: 32px;

  /* bold-title-20 */
  --font-en-bold-title-20-fontSize: 20px;
  --font-en-bold-title-20-fontFamily: Nunito;
  --font-en-bold-title-20-fontWeight: 700;
  --font-en-bold-title-20-fontStyle: normal;
  --font-en-bold-title-20-fontStretch: normal;
  --font-en-bold-title-20-fontStyleOld: Bold;
  --font-en-bold-title-20-letterSpacing: normal;
  --font-en-bold-title-20-lineHeight: 32px;

  /* bold-subtitle-18 */
  --font-en-bold-subtitle-18-fontSize: 18px;
  --font-en-bold-subtitle-18-fontFamily: Nunito;
  --font-en-bold-subtitle-18-fontWeight: 700;
  --font-en-bold-subtitle-18-fontStyle: normal;
  --font-en-bold-subtitle-18-fontStretch: normal;
  --font-en-bold-subtitle-18-fontStyleOld: Bold;
  --font-en-bold-subtitle-18-letterSpacing: normal;
  --font-en-bold-subtitle-18-lineHeight: 32px;

  /* bold-paragraph-16 */
  --font-en-bold-paragraph-16-fontSize: 16px;
  --font-en-bold-paragraph-16-fontFamily: Nunito;
  --font-en-bold-paragraph-16-fontWeight: 700;
  --font-en-bold-paragraph-16-fontStyle: normal;
  --font-en-bold-paragraph-16-fontStretch: normal;
  --font-en-bold-paragraph-16-fontStyleOld: Bold;
  --font-en-bold-paragraph-16-letterSpacing: normal;
  --font-en-bold-paragraph-16-lineHeight: 24px;

  /* bold-paragraph-14 */
  --font-en-bold-paragraph-14-fontSize: 14px;
  --font-en-bold-paragraph-14-fontFamily: Nunito;
  --font-en-bold-paragraph-14-fontWeight: 700;
  --font-en-bold-paragraph-14-fontStyle: normal;
  --font-en-bold-paragraph-14-fontStretch: normal;
  --font-en-bold-paragraph-14-fontStyleOld: Bold;
  --font-en-bold-paragraph-14-letterSpacing: normal;
  --font-en-bold-paragraph-14-lineHeight: 24px;

  /* bold-caption-13 */
  --font-en-bold-caption-13-fontSize: 13px;
  --font-en-bold-caption-13-fontFamily: Nunito;
  --font-en-bold-caption-13-fontWeight: 800;
  --font-en-bold-caption-13-fontStyle: normal;
  --font-en-bold-caption-13-fontStretch: normal;
  --font-en-bold-caption-13-fontStyleOld: ExtraBold;
  --font-en-bold-caption-13-letterSpacing: normal;
  --font-en-bold-caption-13-lineHeight: 18px;

  /* bold-caption-12 */
  --font-en-bold-caption-12-fontSize: 12px;
  --font-en-bold-caption-12-fontFamily: Nunito;
  --font-en-bold-caption-12-fontWeight: 800;
  --font-en-bold-caption-12-fontStyle: normal;
  --font-en-bold-caption-12-fontStretch: normal;
  --font-en-bold-caption-12-fontStyleOld: ExtraBold;
  --font-en-bold-caption-12-letterSpacing: normal;
  --font-en-bold-caption-12-lineHeight: 16px;

  /* bold-caption-11 */
  --font-en-bold-caption-11-fontSize: 11px;
  --font-en-bold-caption-11-fontFamily: Nunito;
  --font-en-bold-caption-11-fontWeight: 800;
  --font-en-bold-caption-11-fontStyle: normal;
  --font-en-bold-caption-11-fontStretch: normal;
  --font-en-bold-caption-11-fontStyleOld: ExtraBold;
  --font-en-bold-caption-11-letterSpacing: normal;
  --font-en-bold-caption-11-lineHeight: 15px;

  /* bold-caption-10 */
  --font-en-bold-caption-10-fontSize: 10px;
  --font-en-bold-caption-10-fontFamily: Nunito;
  --font-en-bold-caption-10-fontWeight: 800;
  --font-en-bold-caption-10-fontStyle: normal;
  --font-en-bold-caption-10-fontStretch: normal;
  --font-en-bold-caption-10-fontStyleOld: ExtraBold;
  --font-en-bold-caption-10-letterSpacing: normal;
  --font-en-bold-caption-10-lineHeight: 16px;

  /* medium-display-64 */
  --font-en-medium-display-64-fontSize: 64px;
  --font-en-medium-display-64-fontFamily: Nunito;
  --font-en-medium-display-64-fontWeight: 500;
  --font-en-medium-display-64-fontStyle: normal;
  --font-en-medium-display-64-fontStretch: normal;
  --font-en-medium-display-64-fontStyleOld: Medium;
  --font-en-medium-display-64-letterSpacing: normal;
  --font-en-medium-display-64-lineHeight: 96px;

  /* medium-display-50 */
  --font-en-medium-display-50-fontSize: 50px;
  --font-en-medium-display-50-fontFamily: Nunito;
  --font-en-medium-display-50-fontWeight: 500;
  --font-en-medium-display-50-fontStyle: normal;
  --font-en-medium-display-50-fontStretch: normal;
  --font-en-medium-display-50-fontStyleOld: Medium;
  --font-en-medium-display-50-letterSpacing: normal;
  --font-en-medium-display-50-lineHeight: 72px;

  /* medium-display-40 */
  --font-en-medium-display-40-fontSize: 40px;
  --font-en-medium-display-40-fontFamily: Nunito;
  --font-en-medium-display-40-fontWeight: 500;
  --font-en-medium-display-40-fontStyle: normal;
  --font-en-medium-display-40-fontStretch: normal;
  --font-en-medium-display-40-fontStyleOld: Medium;
  --font-en-medium-display-40-letterSpacing: normal;
  --font-en-medium-display-40-lineHeight: 54px;

  /* medium-display-46 */
  --font-en-medium-display-46-fontSize: 36px;
  --font-en-medium-display-46-fontFamily: Nunito;
  --font-en-medium-display-46-fontWeight: 500;
  --font-en-medium-display-46-fontStyle: normal;
  --font-en-medium-display-46-fontStretch: normal;
  --font-en-medium-display-46-fontStyleOld: Medium;
  --font-en-medium-display-46-letterSpacing: normal;
  --font-en-medium-display-46-lineHeight: 48px;

  /* medium-headline-24 */
  --font-en-medium-headline-24-fontSize: 24px;
  --font-en-medium-headline-24-fontFamily: Nunito;
  --font-en-medium-headline-24-fontWeight: 500;
  --font-en-medium-headline-24-fontStyle: normal;
  --font-en-medium-headline-24-fontStretch: normal;
  --font-en-medium-headline-24-fontStyleOld: Medium;
  --font-en-medium-headline-24-letterSpacing: normal;
  --font-en-medium-headline-24-lineHeight: 32px;

  /* medium-title-20 */
  --font-en-medium-title-20-fontSize: 20px;
  --font-en-medium-title-20-fontFamily: Nunito;
  --font-en-medium-title-20-fontWeight: 500;
  --font-en-medium-title-20-fontStyle: normal;
  --font-en-medium-title-20-fontStretch: normal;
  --font-en-medium-title-20-fontStyleOld: Medium;
  --font-en-medium-title-20-letterSpacing: normal;
  --font-en-medium-title-20-lineHeight: 32px;

  /* medium-subtitle-18 */
  --font-en-medium-subtitle-18-fontSize: 17px;
  --font-en-medium-subtitle-18-fontFamily: Nunito;
  --font-en-medium-subtitle-18-fontWeight: 500;
  --font-en-medium-subtitle-18-fontStyle: normal;
  --font-en-medium-subtitle-18-fontStretch: normal;
  --font-en-medium-subtitle-18-fontStyleOld: Medium;
  --font-en-medium-subtitle-18-letterSpacing: normal;
  --font-en-medium-subtitle-18-lineHeight: 32px;

  /* medium-paragraph-16 */
  --font-en-medium-paragraph-16-fontSize: 16px;
  --font-en-medium-paragraph-16-fontFamily: Nunito;
  --font-en-medium-paragraph-16-fontWeight: 500;
  --font-en-medium-paragraph-16-fontStyle: normal;
  --font-en-medium-paragraph-16-fontStretch: normal;
  --font-en-medium-paragraph-16-fontStyleOld: Medium;
  --font-en-medium-paragraph-16-letterSpacing: normal;
  --font-en-medium-paragraph-16-lineHeight: 24px;

  /* medium-paragraph-14 */
  --font-en-medium-paragraph-14-fontSize: 14px;
  --font-en-medium-paragraph-14-fontFamily: Nunito;
  --font-en-medium-paragraph-14-fontWeight: 500;
  --font-en-medium-paragraph-14-fontStyle: normal;
  --font-en-medium-paragraph-14-fontStretch: normal;
  --font-en-medium-paragraph-14-fontStyleOld: Medium;
  --font-en-medium-paragraph-14-letterSpacing: normal;
  --font-en-medium-paragraph-14-lineHeight: 24px;

  /* medium-caption-13 */
  --font-en-medium-caption-13-fontSize: 13px;
  --font-en-medium-caption-13-fontFamily: Nunito;
  --font-en-medium-caption-13-fontWeight: 500;
  --font-en-medium-caption-13-fontStyle: normal;
  --font-en-medium-caption-13-fontStretch: normal;
  --font-en-medium-caption-13-fontStyleOld: Medium;
  --font-en-medium-caption-13-letterSpacing: normal;
  --font-en-medium-caption-13-lineHeight: 18px;

  /* medium-caption-12 */
  --font-en-medium-caption-12-fontSize: 12px;
  --font-en-medium-caption-12-fontFamily: Nunito;
  --font-en-medium-caption-12-fontWeight: 500;
  --font-en-medium-caption-12-fontStyle: normal;
  --font-en-medium-caption-12-fontStretch: normal;
  --font-en-medium-caption-12-fontStyleOld: Medium;
  --font-en-medium-caption-12-letterSpacing: normal;
  --font-en-medium-caption-12-lineHeight: 16px;

  /* medium-caption-11 */
  --font-en-medium-caption-11-fontSize: 11px;
  --font-en-medium-caption-11-fontFamily: Nunito;
  --font-en-medium-caption-11-fontWeight: 500;
  --font-en-medium-caption-11-fontStyle: normal;
  --font-en-medium-caption-11-fontStretch: normal;
  --font-en-medium-caption-11-fontStyleOld: Medium;
  --font-en-medium-caption-11-letterSpacing: normal;
  --font-en-medium-caption-11-lineHeight: 15px;

  /* medium-caption-10 */
  --font-en-medium-caption-10-fontSize: 10px;
  --font-en-medium-caption-10-fontFamily: Nunito;
  --font-en-medium-caption-10-fontWeight: 500;
  --font-en-medium-caption-10-fontStyle: normal;
  --font-en-medium-caption-10-fontStretch: normal;
  --font-en-medium-caption-10-fontStyleOld: Medium;
  --font-en-medium-caption-10-letterSpacing: normal;
  --font-en-medium-caption-10-lineHeight: 16px;

  /* light-display-64 */
  --font-en-light-display-64-fontSize: 64px;
  --font-en-light-display-64-fontFamily: Nunito;
  --font-en-light-display-64-fontWeight: 300;
  --font-en-light-display-64-fontStyle: normal;
  --font-en-light-display-64-fontStretch: normal;
  --font-en-light-display-64-fontStyleOld: Light;
  --font-en-light-display-64-letterSpacing: normal;
  --font-en-light-display-64-lineHeight: 96px;

  /* light-display-50 */
  --font-en-light-display-50-fontSize: 50px;
  --font-en-light-display-50-fontFamily: Nunito;
  --font-en-light-display-50-fontWeight: 300;
  --font-en-light-display-50-fontStyle: normal;
  --font-en-light-display-50-fontStretch: normal;
  --font-en-light-display-50-fontStyleOld: Light;
  --font-en-light-display-50-letterSpacing: normal;
  --font-en-light-display-50-lineHeight: 72px;

  /* light-display-40 */
  --font-en-light-display-40-fontSize: 40px;
  --font-en-light-display-40-fontFamily: Nunito;
  --font-en-light-display-40-fontWeight: 300;
  --font-en-light-display-40-fontStyle: normal;
  --font-en-light-display-40-fontStretch: normal;
  --font-en-light-display-40-fontStyleOld: Light;
  --font-en-light-display-40-letterSpacing: normal;
  --font-en-light-display-40-lineHeight: 54px;

  /* light-display-46 */
  --font-en-light-display-46-fontSize: 36px;
  --font-en-light-display-46-fontFamily: Nunito;
  --font-en-light-display-46-fontWeight: 300;
  --font-en-light-display-46-fontStyle: normal;
  --font-en-light-display-46-fontStretch: normal;
  --font-en-light-display-46-fontStyleOld: Light;
  --font-en-light-display-46-letterSpacing: normal;
  --font-en-light-display-46-lineHeight: 48px;

  /* light-headline-24 */
  --font-en-light-headline-24-fontSize: 24px;
  --font-en-light-headline-24-fontFamily: Nunito;
  --font-en-light-headline-24-fontWeight: 300;
  --font-en-light-headline-24-fontStyle: normal;
  --font-en-light-headline-24-fontStretch: normal;
  --font-en-light-headline-24-fontStyleOld: Light;
  --font-en-light-headline-24-letterSpacing: normal;
  --font-en-light-headline-24-lineHeight: 32px;

  /* light-title-20 */
  --font-en-light-title-20-fontSize: 20px;
  --font-en-light-title-20-fontFamily: Nunito;
  --font-en-light-title-20-fontWeight: 300;
  --font-en-light-title-20-fontStyle: normal;
  --font-en-light-title-20-fontStretch: normal;
  --font-en-light-title-20-fontStyleOld: Light;
  --font-en-light-title-20-letterSpacing: normal;
  --font-en-light-title-20-lineHeight: 32px;

  /* light-subtitle-18 */
  --font-en-light-subtitle-18-fontSize: 18px;
  --font-en-light-subtitle-18-fontFamily: Nunito;
  --font-en-light-subtitle-18-fontWeight: 300;
  --font-en-light-subtitle-18-fontStyle: normal;
  --font-en-light-subtitle-18-fontStretch: normal;
  --font-en-light-subtitle-18-fontStyleOld: Light;
  --font-en-light-subtitle-18-letterSpacing: normal;
  --font-en-light-subtitle-18-lineHeight: 32px;

  /* light-paragraph-16 */
  --font-en-light-paragraph-16-fontSize: 16px;
  --font-en-light-paragraph-16-fontFamily: Nunito;
  --font-en-light-paragraph-16-fontWeight: 300;
  --font-en-light-paragraph-16-fontStyle: normal;
  --font-en-light-paragraph-16-fontStretch: normal;
  --font-en-light-paragraph-16-fontStyleOld: Light;
  --font-en-light-paragraph-16-letterSpacing: normal;
  --font-en-light-paragraph-16-lineHeight: 24px;

  /* light-paragraph-14 */
  --font-en-light-paragraph-14-fontSize: 14px;
  --font-en-light-paragraph-14-fontFamily: Nunito;
  --font-en-light-paragraph-14-fontWeight: 300;
  --font-en-light-paragraph-14-fontStyle: normal;
  --font-en-light-paragraph-14-fontStretch: normal;
  --font-en-light-paragraph-14-fontStyleOld: Light;
  --font-en-light-paragraph-14-letterSpacing: normal;
  --font-en-light-paragraph-14-lineHeight: 24px;

  /* light-caption-13 */
  --font-en-light-caption-13-fontSize: 13px;
  --font-en-light-caption-13-fontFamily: Nunito;
  --font-en-light-caption-13-fontWeight: 300;
  --font-en-light-caption-13-fontStyle: normal;
  --font-en-light-caption-13-fontStretch: normal;
  --font-en-light-caption-13-fontStyleOld: Light;
  --font-en-light-caption-13-letterSpacing: normal;
  --font-en-light-caption-13-lineHeight: 18px;

  /* light-caption-12 */
  --font-en-light-caption-12-fontSize: 12px;
  --font-en-light-caption-12-fontFamily: Nunito;
  --font-en-light-caption-12-fontWeight: 300;
  --font-en-light-caption-12-fontStyle: normal;
  --font-en-light-caption-12-fontStretch: normal;
  --font-en-light-caption-12-fontStyleOld: Light;
  --font-en-light-caption-12-letterSpacing: normal;
  --font-en-light-caption-12-lineHeight: 16px;

  /* light-caption-11 */
  --font-en-light-caption-11-fontSize: 11px;
  --font-en-light-caption-11-fontFamily: Nunito;
  --font-en-light-caption-11-fontWeight: 300;
  --font-en-light-caption-11-fontStyle: normal;
  --font-en-light-caption-11-fontStretch: normal;
  --font-en-light-caption-11-fontStyleOld: Light;
  --font-en-light-caption-11-letterSpacing: normal;
  --font-en-light-caption-11-lineHeight: 16px;

  /* light-caption-10 */
  --font-en-light-caption-10-fontSize: 10px;
  --font-en-light-caption-10-fontFamily: Nunito;
  --font-en-light-caption-10-fontWeight: 300;
  --font-en-light-caption-10-fontStyle: normal;
  --font-en-light-caption-10-fontStretch: normal;
  --font-en-light-caption-10-fontStyleOld: Light;
  --font-en-light-caption-10-letterSpacing: normal;
  --font-en-light-caption-10-lineHeight: 16px;

  --brand-font-family: Nunito, serif;
}
