.checkbox_Style {
  margin: 0 !important;
}

.checkbox_Style svg {
  color: var(--brand-primary);
}

.primary_checkbox,
.primary_checkbox p,
.primary_checkbox svg,
.primary_checkbox span {
  color: var(--brand-primary);
}

.secondary_checkbox,
.secondary_checkbox p,
.secondary_checkbox svg,
.secondary_checkbox span {
  color: var(--brand-secondary);
}

.info_checkbox,
.info_checkbox p,
.info_checkbox svg,
.info_checkbox span {
  color: var(--brand-info-primary);
}

.success_checkbox,
.success_checkbox p,
.success_checkbox svg,
.success_checkbox span {
  color: var(--brand-success-primary);
}

.warning_checkbox,
.warning_checkbox p,
.warning_checkbox svg,
.warning_checkbox span {
  color: var(--brand-warning-primary);
}

.error_checkbox,
.error_checkbox p,
.error_checkbox svg,
.error_checkbox span {
  color: var(--brand-error-primary);
}
