.toggleButton > button {
  font-weight: 800;
  color: #6c6b6f;
  border: 1px solid #6c6b6f;
}

.toggleButton > button[class*="Mui-selected"] {
  color: #fff;
  /* add important to prevent hover */
  background: #6c6b6f !important;
}
