.dataTable .row {
  width: 100%;
  color: var(--brand-text-primary);
}

.dataTable.root {
  border: none !important;
  outline: none !important;
  width: 100%;
}

.dataTable.root .columnSeparator {
  display: none;
}

.dataTable.root .columnHeaderTitleContainer {
  padding: 0;
  height: 100%;
  max-height: 60px !important;
  overflow-y: hidden;
}

.dataTable .columnHeaders,
.dataTable .main {
  padding: 0 1.5rem;
}

.dataTable .columnHeaderTitle {
  font-size: 14px;
  font-family: var(--brand-font-family);
  font-weight: 700;
  color: var(--brand-gray-secondary);
}

.headerClassName {
  outline: none !important;
}

.dataTable .row.selected {
  background-color: transparent;
}

.dataTable .cell {
  font-weight: 700;
  outline: none !important;
}

.dataTable .checkboxInput:hover,
.dataTable .checkboxInput:focus {
  background-color: transparent;
}

.dataTable .checkboxInput svg {
  width: 0.8em;
  height: 0.8em;
  color: #d2d2d2;
  border-radius: 1px;
}

.dataTable .checkboxInput input:checked + svg {
  color: var(--brand-text-primary);
}

.dataTable .footerContainer p,
.dataTable .selectedRowCount {
  color: var(--brand-gray-secondary);
}

@media (max-width: 640px) {
  .pagination_totals_section {
    flex-direction: column;
  }
}
