.datepicker_container {
  position: relative;
  padding: 5px 0;
  cursor: pointer;
}

.datepicker_container svg {
  position: absolute;
  top: 0.7rem;
  right: 0.6rem;
  opacity: 0.5;
}

[lang="ar"] .datepicker_container svg {
  right: unset;
  left: 0.5rem;
}

.datepicker_container [class*="react-datepicker-popper"] {
  z-index: 999;
}

.custom_input .datepicker {
  padding: 1rem;
}

.datepicker_container:hover svg {
  opacity: 0.9;
}

.datepicker_label {
  background-color: var(--brand-background-modal);
  padding: 0 8px;
  position: absolute;
  z-index: 1000;
  top: -4px;
  left: 10px;
}

[lang="ar"] .datepicker_label {
  left: unset;
  right: 10px;
}

.datepicker {
  background-color: var(--brand-background-modal);
  padding: 0.5rem;
  border: 1px solid var(--brand-teritary);
  border-radius: 4px;
  width: -webkit-fill-available;
  outline-color: var(--brand-border-input);
  cursor: pointer;
}

.error_textfield {
  outline-color: var(--brand-border-input-error);
  border: 2px solid var(--brand-border-input-error);
}

.datepicker:hover {
  border-color: var(--brand-gray-primary);
}

.wrapper_className {
  padding: 0;
  margin: 0;
}

.react-datepicker__input-container {
  padding: 0;
  margin: 0;
}

.react-datepicker__input-container input {
  padding: 0;
  margin: 0;
}
