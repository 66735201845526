.containedStyle {
  width: 100%;
  padding: 0.8rem;
  background-color: var(--brand-primary) !important;
}

.outlinedStyle {
  border-color: var(--brand-secondary) !important;
  box-shadow: 0 1px 3px var(--brand-secondary);
}

.outlinedStyle svg {
  margin: 0 !important;
}

.textStyle {
  background-color: unset !important;
  border: none;
  box-shadow: none;
}

.disabledStyle {
  background-color: var(--brand-gray-secondary) !important;
  color: var(--brand-text-white);
}

/* .error_theme,
.info_theme,
.primary_theme,
.secondary_theme,
.success_theme,
.warning_theme {
    border: unset !important;
    background-color: unset !important;

} */
