.options {
  padding: 5px 0;
  min-width: 150px;
}

.options > div {
  cursor: pointer;
  height: 40px;
  width: inherit;
  padding: 0 10px;
}

.options > div:hover {
  background: #e3dcdc;
}

.options > .activeItem {
  background: #c5bebe !important;
  cursor: default;
}
