.groupboxContainer {
  position: relative;
  border: 1px solid var(--brand-secondary);
  width: 50%;
  padding: 16px;
  align-self: center;
  border-radius: 8px;
}

.groupboxContainerTitle {
  position: absolute;
  top: -20px;
  background-color: var(--brand-background-modal);
  padding: 2px 8px;
}
