.MuiTooltip-popper .MuiTooltip-tooltip {
  font-weight: var(--font-en-medium-caption-12-fontWeight);
  font-size: var(--font-en-medium-caption-12-fontSize);
  font-family: var(--font-en-medium-caption-12-fontFamily);
  font-style: var(--font-en-medium-caption-12-fontStyle);
  line-height: var(--font-en-medium-caption-12-lineHeight);
  letter-spacing: var(--font-en-medium-caption-12-letterSpacing);
  background-color: var(--brand-text-primary);
}

.MuiTooltip-popper .MuiTooltip-arrow {
  color: var(--brand-text-primary);
}
