.table {
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  width: 100%;
  background-color: transparent;
  height: 100%;
}

.table thead > tr {
  background-color: #eae6e6;
  position: sticky;
  top: 0;
  z-index: 100;
}

.table thead > tr > th,
.table tbody > tr > td {
  padding: 10px;
  min-height: 20px;
  text-align: start;
}

.table tbody > tr > td {
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}

.table tbody > tr > td:last-child {
  border-right: 1px solid #e6e6e6;
}

[lang="ar"] .table tbody > tr > td {
  border-left: unset;
  border-right: 1px solid #e6e6e6;
}

[lang="ar"] .table tbody > tr > td:last-child {
  border-left: 1px solid #e6e6e6;
}

.arrowIcon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  transition: background 0.1s ease-in-out;
}

.arrowIcon:hover {
  background: var(--brand-gray-secondary);
}
