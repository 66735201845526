.button_loader {
  background-color: var(--brand-secondary);
  padding: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation-duration: 0.4s;
  animation-name: loading_button;
}

.filter_button {
  border-color: var(--brand-gray-secondary) !important;
  border-radius: 6px !important;
  box-shadow: unset;
  height: 40px !important;
  min-width: unset !important;
  padding: 0 !important;
  width: 40px;
}

.clear_filters_icon {
  fill: var(--brand-text-primary);
  cursor: pointer;
  width: 36px;
  height: 29px;
}

.clear_filters_icon:hover path {
  fill: var(--brand-gray-primary);
}

.clear_filters_button {
  background-color: unset !important;
}

.export_icons {
  color: var(--brand-text-primary) !important;
}

.export_icons path {
  fill: var(--brand-text-primary) !important;
}

@keyframes loading_button {
  from {
    width: 100%;
    border-radius: 4px;
  }
  to {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
